import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { SuccessIcon } from 'assets/icons/SuccessIcon';
import { ErrorIcon } from 'assets/icons/ErrorIcon';
import { useSelector } from 'store/hook';
import { LICENSE_STATE_PAGE } from 'global/routes';
import { KLLink } from 'components/KLLink';
import { infoRequest } from 'services/activation/slice';
import { useDispatch } from 'react-redux';
import { SUPERVISOR } from 'services/auth/constants';
import { LicenseStateProps } from './types';
import styles from './styles';

const useStyles = makeStyles(styles);

export const LicenseState = (props: LicenseStateProps) => {
  const {
    isLicenseExists,
    daysLeftToUse,
    clientIsActive,
  } = useSelector(state => state.activation);
  const { isAuthenticated, clientId } = useSelector(state => state.auth);
  const { appBarOpen, disabled } = props;
  const dispatch = useDispatch();
  const roleName = useSelector(state => state.auth.roleName);

  useEffect(() => {
    if (isAuthenticated && clientId) {
      dispatch(infoRequest());
    }
  }, [dispatch, isAuthenticated, clientId]);

  const { t } = useTranslation('LicenseStateBar');
  const classes = useStyles(appBarOpen);

  const activeItem = (
    <>
      <SuccessIcon className={classes.icon} />
      {appBarOpen 
        && t(
          'Application is activated',
          { daysLeftToUse: (daysLeftToUse !== null) ? daysLeftToUse : t('Not limited') },
        )}
    </>
  );

  const notActiveItem = (
    <>
      <ErrorIcon className={classes.icon} />
      {appBarOpen && t('Application is not activated')}
    </>
  );

  const LicenseComponent = () => (disabled || roleName !== SUPERVISOR ? (
    <Box className={classNames(classes.root, classes.active)}> 
      {
        (daysLeftToUse || daysLeftToUse === null) && clientIsActive ? (
          activeItem
        ) : (
          notActiveItem
        )
      }
    </Box>
  ) : (
    <KLLink
      className={classNames(classes.root, classes.active)}
      to={LICENSE_STATE_PAGE}
    >
      {(daysLeftToUse || daysLeftToUse === null) && clientIsActive ? (
        activeItem
      ) : (
        notActiveItem
      )}
    </KLLink>
  ));

  if (clientIsActive === null) {
    return null;
  }
  return (
    <Box>
      {isLicenseExists ? <LicenseComponent /> : (
        <Box className={classes.root}>
          {notActiveItem}
        </Box>
      )}
    </Box>
  );
};
