import { createStyles, Theme } from '@material-ui/core/styles';
import { backgroundColors } from 'themes/default';

export default (theme: Theme) => createStyles({
  root: {
    boxShadow: 'none',
    '& .MuiTableRow-head': {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    '& .MuiTableRow-root:first-child:not(.MuiTableRow-footer)': {
      boxShadow: 'inset 0px 8px 4px -4px rgba(0, 0, 0, 0.25)',
    },
    '& .MuiTableRow-root:nth-child(odd):not(.MuiTableRow-footer)': {
      backgroundColor: '#f6f6f6',
    },
    '& .MuiTableRow-root:nth-child(even)': {
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiTableRow-root:not([index]):not(:last-child)': {
      height: '40px !important',
    },
    '& .MuiTableRow-root:not([index]):not(:last-child):nth-child(odd)::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '40px !important',
      backgroundColor: '#f6f6f6',
    },
    '& .MuiTableRow-hover:hover': {
      backgroundColor: '#E1F4FF !important',
    },
    '& .MuiTableCell-body': {
      lineHeight: theme.typography.pxToRem(24),
      borderBottom: 'none',
      whiteSpace: 'nowrap' as 'nowrap',
      padding: theme.spacing(1, 2),
      minHeight: 40,
    },
    '& .MuiTableCell-body:first-child:last-child': {
      height: 40,
      verticalAlign: 'middle',
    },
    '& .MuiTableCell-head .MuiTableSortLabel-root': {
      cursor: 'initial',
    },
    '& .MuiTableCell-head .MuiTableSortLabel-root.Mui-disabled': {
      backgroundColor: '#FFFFFF',
    },
    '&>.MuiPaper-elevation2': {
      boxShadow: 'none',
    },
    '&>.MuiPaper-rounded': {
      borderRadius: 0,
    },
    '&>.MuiPaper-root': {
      transition: 'none',
    },
  },
  paginationContainer: {
    padding: theme.spacing(2, 4),
  },
  paginationLoadableContainer: {
    padding: theme.spacing(3, 0),
  },
  pagination: {
    marginRight: theme.spacing(2.75),
  },
  paginationText: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    '& svg': {
      fill: theme.palette.primary.main,
    },
    lineHeight: theme.typography.pxToRem(32),
    padding: theme.spacing(0, 1.5),
    maxHeight: theme.typography.pxToRem(32),
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: backgroundColors?.primary?.main,
    },
  },
  paginationDisabled: {
    color: '#cccccc',
    '& svg': {
      fill: '#cccccc',
    },
  },
  paginationCurrent: {
    border: '1px solid #58595B',
    color: '#58595B',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  paginationEnd: {
    '&:first-child': {
      marginRight: theme.spacing(1),
    },
    '&:last-child': {
      marginLeft: theme.spacing(1),
    },
  },
  paginationEllipsis: {
    backgroundColor: 'transparent',
    padding: 0,
  },
  entries: {
    lineHeight: theme.typography.pxToRem(32),
  },
  filtersBadge: {
    backgroundColor: '#58C3FF',
  },
  header: {
    padding: theme.spacing(1, 6),
  },
});
