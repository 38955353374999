import { all } from 'redux-saga/effects';
import { authSaga } from 'services/auth/saga';
import { incidentSaga } from 'services/incidents/saga';
import { assetSaga } from 'services/assets/saga';
import { commentSaga } from 'services/comments/saga';
import { incidentResponses } from 'services/incidentResponses/saga';
import { attachmentSaga } from 'services/attachments/saga';
import { activationSaga } from 'services/activation/saga';
import { userSaga } from 'services/user/saga';
import { permissionsSaga } from 'services/permissions/saga';
import { statisticsSaga } from 'services/statistics/saga';
import { notificationsSaga } from 'services/notifications/saga';
import { clientInformerSaga } from 'services/clientInformer/saga';
import { organizationsSaga } from 'services/organizations/saga';
import { incidentHistorySaga } from 'services/incidentHistory/saga';
import { APIKeysSaga } from 'services/APIkeys/saga';
import { tenantsSaga } from 'services/tenants/saga';
import { settingsSaga } from '../../services/settings/saga';
import { licensesSaga } from '../../services/licenses/saga';

export default function* rootSaga() {
  yield all([
    ...APIKeysSaga,
    ...activationSaga,
    ...assetSaga,
    ...attachmentSaga,
    ...authSaga,
    ...clientInformerSaga,
    ...commentSaga,
    ...incidentHistorySaga,
    ...incidentResponses,
    ...incidentSaga,
    ...notificationsSaga,
    ...organizationsSaga,
    ...permissionsSaga,
    ...settingsSaga,
    ...statisticsSaga,
    ...tenantsSaga,
    ...userSaga,
    ...licensesSaga,
  ]);
}
