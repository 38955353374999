import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { load } from 'utils/storage';
import { check } from 'services/auth/saga';
import { getLang, getT } from 'utils/i18n';
import { addNotification } from 'services/system/slice';
import { requestLicenses } from 'services/licenses/slice';
import { LICENSE_ALREADY_ACTIVATED } from '../../global/errors';
import {
  agreementDecideRequest,
  agreementDecideStart,
  agreementDecideSuccess, 
  agreementListForActivationRequest,
  agreementListRequest,
  agreementListSuccess,
  agreementRequest,
  agreementListForActivationSuccess,
  agreementStart,
  agreementSuccess,
  confirmRequest,
  confirmSuccess,
  downloadLicenseDocRequest,
  downloadMDRConfigRequest,
  error,
  getLicenseRequest,
  getLicenseSuccess,
  infoRequest,
  infoSuccess,
  skipUpdateNotice,
  start,
  validateRequest,
  validateSuccess,
  originMismatch,
} from './slice';

import {
  downloadLicenseDoc,
  downloadMDRConfig,
  getAgreements,
  getAgreement,
  getInfo,
  getLicense,
  postConfirm,
  postValidate,
  decideAgreement,
} from './api';
import {
  AgreementDecision,
  AgreementOffer,
  RequestActivationAction,
  Agreement,
  AgreementPayload,
  AgreementDecidePayload,
  AgreementType, AgreementForActivationPayload,
} from './types';

function* requestValidate(action: RequestActivationAction) {
  yield put(start());
  const { idToken } = yield select(store => store.auth);

  try {
    const validationResult = yield call(postValidate, action.payload, idToken);
    yield put(validateSuccess(validationResult));
  } catch (err) {
    const message = err.message || err;
    if (message === 'originMismatch' && typeof err?.data?.expectedOrigin === 'string') {
      yield put(originMismatch(err.data.expectedOrigin));
    } else {
      yield put(error(message));
    }
  }
}

function* requestConfirm(action: PayloadAction<number[] | undefined>) {
  yield put(start());
  const { idToken } = yield select(store => store.auth);
  const { code, region, kata } = yield select(state => state.activation);

  try {
    yield call(postConfirm, { activationCode: code, region, kata, idToken });
    yield call(check, true); // get actual user info
    if (action.payload) {
      for (const agreementId of action.payload) {
        yield call(decideAgreement, { agreementId, accepted: true });
      }
    }
    yield put(confirmSuccess());
    yield put(infoRequest());
    yield put(requestLicenses({}));
    yield put(agreementListRequest(AgreementType.MDR));
  } catch (err) {
    const message = err.message || err;
    const trMessage = message === LICENSE_ALREADY_ACTIVATED ? getT('errors:License already activated') : message;
    yield put(addNotification({ message: trMessage, options: { variant: 'error' } }));
    yield put(error(message));
  }
}

function* requestInfo() {
  yield put(start());

  try {
    const response = yield call(getInfo);
    yield put(infoSuccess(response));

    const isSkippedUpdateNotice = load('isSkippedUpdateNotice');
    if (isSkippedUpdateNotice) {
      yield put(skipUpdateNotice());
    }

  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
  }
}

function* downloadMDRConfigSaga(action: any) {
  const licenseId = action.payload;
  const purpose = 0;
  try {
    yield call(downloadMDRConfig, licenseId, purpose);
  } catch (err) {
    const message = err.message || err;
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* getLicenseSaga() {
  const { region } = yield select(state => state.activation);
  const lang = getLang();

  try {
    const { licenseContent } = yield call(getLicense, region, lang);
    yield put(getLicenseSuccess(licenseContent));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
  }
}

function* downloadLicenseDocSaga(action: any) {
  const lang = getLang();
  const type = action.payload;

  try {
    yield call(downloadLicenseDoc, type, lang);
  } catch (err) {
    const message = err.message || err;
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* requestAgreements(action: PayloadAction<AgreementType>) {
  try {
    const agreements: AgreementOffer[] = yield call(getAgreements, action.payload);
    const filteredAgreements = agreements.filter(agr => agr.decision !== AgreementDecision.ACCEPTED);
    yield put(agreementListSuccess(filteredAgreements.length ? filteredAgreements : null));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* requestAgreementsForActivation(action: PayloadAction<AgreementForActivationPayload>) {
  try {
    const agreementsDict: { [key: string]: Agreement } = {};
    const { titles, ...rest } = action.payload;
    const { agreements } = yield select(state => state.activation);

    for (const title of titles) {
      if (!agreements || !agreements.find((agr: AgreementOffer) => agr.meta.title === title)) {
        agreementsDict[title] = yield call(getAgreement, { title, ...rest });
      }
    }
    yield put(agreementListForActivationSuccess(agreementsDict));
  } catch (err) {
    const message = err.message || err;
    yield put(agreementListForActivationSuccess(null));
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* requestAgreement(action: PayloadAction<AgreementPayload>) {
  yield put(agreementStart());
  try {
    const agreement: Agreement = yield call(getAgreement, action.payload);
    yield put(agreementSuccess(agreement));
  } catch (err) {
    const message = err.message || err;
    yield put(agreementSuccess(null));
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

function* requestAgreementDecide(action: PayloadAction<AgreementDecidePayload>) {
  yield put(agreementDecideStart());
  try {
    const agreement: AgreementOffer = yield call(decideAgreement, action.payload);
    yield put(agreementDecideSuccess(agreement));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));
    yield put(addNotification({ message, options: { variant: 'error' } }));
  }
}

export const activationSaga = [
  takeLatest(validateRequest.type, requestValidate),
  takeLatest(confirmRequest.type, requestConfirm),
  takeLatest(infoRequest.type, requestInfo),
  takeLatest(getLicenseRequest.type, getLicenseSaga),
  takeLatest(downloadMDRConfigRequest.type, downloadMDRConfigSaga),
  takeLatest(downloadLicenseDocRequest.type, downloadLicenseDocSaga),
  takeLatest(agreementListRequest.type, requestAgreements),
  takeLatest(agreementListForActivationRequest.type, requestAgreementsForActivation),
  takeLatest(agreementRequest.type, requestAgreement),
  takeLatest(agreementDecideRequest.type, requestAgreementDecide),
];
