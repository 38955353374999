import { takeLatest, put, call } from 'redux-saga/effects';
import { licensesRequested, licensesResolved, licensesRejected, requestLicenses } from './slice';
import { getLicensesList } from './api';
import { LicenseItem } from './types';

function* requestLicensesSaga(params: any) {
  try {
    yield put(licensesRequested(params));
    const licenses: LicenseItem[] = yield call(getLicensesList, params);
    yield put(licensesResolved({ licenses }));
  } catch (err) {
    const message = err.message || err;
    yield put(licensesRejected(message));
  }
}

export const licensesSaga = [
  takeLatest(requestLicenses, requestLicensesSaga),
];
