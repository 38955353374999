import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import isEqual from 'react-fast-compare';
import { addNotification } from '../system/slice';
import { INCIDENT_REVOKED, NOT_FOUND } from '../../global/errors';
import {
  error,
  incidentHistoryRequest,
  listStart,
  listSuccess,
} from './slice';
import {
  IncidentHistoryEntityType,
  IncidentHistoryItem,
  IncidentHistoryItemValue,
  IncidentHistoryOperation,
  IncidentHistorySagaItem,
} from './types';
import * as api from './api';

const safeJsonParse = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

const convertList = (history: IncidentHistorySagaItem[]) => {
  type DiffResult = {
    oldValue: any;
    newValue: any;
  };
  function diff(obj1: Record<string, any>, obj2: Record<string, any>): Record<string, DiffResult> | undefined {
    const result: Record<string, DiffResult> = {};

    if (Object.is(obj1, obj2)) {
      return undefined;
    }

    Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach(key => {
      if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
        if (!isEqual(obj1[key], obj2[key])) {
          result[key] = { oldValue: JSON.stringify(obj1[key]), newValue: JSON.stringify(obj2[key]) };
        }
      } else if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
        result[key] = { oldValue: obj1[key], newValue: obj2[key] };
      }
    });
    return result;
  }
  const historyResult: IncidentHistoryItem[] = [];
  const hashHistory = history.reduce<Record<string, IncidentHistorySagaItem[]>>((acc, cur) => ({
    ...acc, [cur.entityId]: (acc[cur.entityId] || []).concat(cur),
  }), {}) || {};
  const hashHistoryIndex = history.reduce<Record<string, number>>((acc, cur) => ({
    ...acc, [cur.entityId]: 0,
  }), {}) || {};
  const allowedFields: Record<IncidentHistoryEntityType, Record<string, string>> = {
    [IncidentHistoryEntityType.Incident]: {
      summary: 'Summary',
      priority: 'Priority',
      status: 'Status',
      resolution: 'Resolution',
      affectedHosts: 'Affected assets',
      hostBasedIocs: 'Asset-based IOCs',
      networkBasedIocs: 'Network-based IOCs',
      detectionTechnology: 'Detection technology',
      mitreTactics: 'MITRE Tactics',
      mitreTechniques: 'MITRE Techniques',
      description: 'Description',
      statusDescription: 'Status description',
      clientDescription: 'Client description',
      recommendations: 'Recommendations',
      clientComment: 'Client comment',
    },
    [IncidentHistoryEntityType.Response]: {
      status: 'Status',
      description: 'Comment',
      comment: 'Comment',
      assetId: 'Asset ID',
      type: 'Type',
    },
    [IncidentHistoryEntityType.Comment]: {
      text: 'text',
    },
    [IncidentHistoryEntityType.Attachment]: {
      caption: 'caption',
      fileName: 'Filename',
      fileSize: 'File size',
    },
    [IncidentHistoryEntityType.EDRResponse]: {
      type: 'Type',
      details: 'Details',
    },
  };

  const markdownFields: Record<IncidentHistoryEntityType, string[]> = {
    [IncidentHistoryEntityType.Comment]: ['text'],
    [IncidentHistoryEntityType.Attachment]: ['caption'],
    [IncidentHistoryEntityType.Incident]: [
      'recommendations',
      'description',
      'clientDescription',
      'statusDescription',
      'clientComment',
    ],
    [IncidentHistoryEntityType.EDRResponse]: [],
    [IncidentHistoryEntityType.Response]: [],
  };
  const isMarkdown = (entityType: IncidentHistoryEntityType, field: string): boolean => (
        markdownFields[entityType]?.includes(field) || false
  );

  history.forEach(({ changedBy, changedAt, entityType, entityId, operation }) => {
    const curIndex = hashHistoryIndex[entityId];
    const curItem = hashHistory[entityId];
    const before: IncidentHistoryItemValue = {};
    const after: IncidentHistoryItemValue = {};

    if (operation === IncidentHistoryOperation.Update) {
      const diffList = diff(curItem[curIndex].entity, curItem[curIndex + 1]?.entity || {}) || {};
      Object.keys(diffList).forEach((diffKey) => {
        if (allowedFields[entityType][diffKey]) {
          before[diffKey] = {
            value: safeJsonParse(diffList[diffKey].oldValue),
            isMarkdown: isMarkdown(entityType, diffKey),
            trKey: allowedFields[entityType][diffKey],
          };
          after[diffKey] = {
            value: safeJsonParse(diffList[diffKey].newValue),
            isMarkdown: isMarkdown(entityType, diffKey),
            trKey: allowedFields[entityType][diffKey],
          };
        }
      });
      hashHistoryIndex[entityId] = curIndex + 1;
    } else if (operation === IncidentHistoryOperation.Create) {
      Object.keys(curItem[curIndex].entity).forEach((key) => {
        if (allowedFields[entityType][key]) {
          after[key] = {
            value: safeJsonParse((curItem[curIndex].entity as any)[key]),
            isMarkdown: isMarkdown(entityType, key),
            trKey: allowedFields[entityType][key],
          };
        }
      });
    } else {
      Object.keys(curItem[curIndex + 1].entity).forEach((key) => {
        if (allowedFields[entityType][key]) {
          before[key] = {
            value: safeJsonParse((curItem[curIndex + 1].entity as any)[key]),
            isMarkdown: isMarkdown(entityType, key),
            trKey: allowedFields[entityType][key],
          };
        }
      });
    }

    if (Object.keys(before).length || Object.keys(after).length) {
      historyResult.push({
        changedBy,
        changedAt,
        entityType,
        operation,
        before,
        after,
      });
    }
  });
  return historyResult;
};

function* getList(action: PayloadAction<string>) {
  yield put(listStart());
  try {
    const list: IncidentHistorySagaItem[] = yield call(api.getIncidentHistory, action.payload);
    yield put(listSuccess(convertList(list)));
  } catch (err) {
    const message = err.message || err;
    yield put(error(message));

    if (message !== NOT_FOUND && message !== INCIDENT_REVOKED) {
      yield put(addNotification({ message, options: { variant: 'error' } }));
    }
  }
}

export const incidentHistorySaga = [
  takeLatest(incidentHistoryRequest.type, getList),
];
