import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from 'components/Title';
import { getLocale } from 'utils/i18n';
import styles from './styles';

const useStyles = makeStyles(styles);

export const GuidePage = () => {
  const { t } = useTranslation(['LicensesTable']);
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.activationHelp}>
      <Title>{t('Help.Title')}</Title>
      <Typography className={classes.subtitle}>{t('Help.KES deployment')}</Typography> 
      <Typography className={classes.subtitle}>
        <Trans
          defaults="LicensesTable:Help:Old version note"
          values={{ link: t('LicensesTable:Help:Old version note link') }}
          components={[
            (
              <a
                className={classes.link}
                href={t('LicensesTable:Help:Old version note link href')}
                target="_blank"
                rel="noopener noreferrer"
              >
                placeholder
              </a>
            ),
          ]}
        />  
      </Typography> 
      <Typography>
        <Trans
          defaults="LicensesTable:Help:Full supported apps list"
          values={{ link: t('LicensesTable:Help:Full supported apps list link') }}
          components={[
            (
              <a
                className={classes.link}
                href={t('LicensesTable:Help:Full supported apps list link href')}
                target="_blank"
                rel="noopener noreferrer"
              >
                placeholder
              </a>
            ),
          ]}
        />  
      </Typography> 
      <ol>
        <li className={classes.listItem}>
          <Trans
            defaults="LicensesTable:Help:Bullet 1"
            values={{ link: t('LicensesTable:Help:Bullet 1 link') }}
            components={[
              (
                <a
                  className={classes.link}
                  href={t('LicensesTable:Help:Bullet 1 link href')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  placeholder
                </a>
              ),
            ]}
          />
        </li>
        <li className={classes.listItem}>
          <Trans
            defaults="LicensesTable:Help:Bullet 2"
            values={{ link: t('LicensesTable:Help:Bullet 2 link') }}
            components={[
              (
                <a
                  className={classes.link}
                  href={t('LicensesTable:Help:Bullet 2 link href')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  placeholder
                </a>
              ),
            ]}
          />
        </li>
        <li className={classes.listItem}>
          <Trans
            defaults="LicensesTable:Help:Bullet 3"
            values={{ link: t('LicensesTable:Help:Bullet 3 link') }}
            components={[
              (
                <a
                  className={classes.link}
                  href={t('LicensesTable:Help:Bullet 3 link href')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  placeholder
                </a>
              ),
            ]}
          />
        </li>
        <li className={classes.listItem}>
          <Trans
            defaults="LicensesTable:Help:Bullet 4"
            values={{ link: t('LicensesTable:Help:Bullet 4 link') }}
            components={[
              (
                <a
                  className={classes.link}
                  href={t('LicensesTable:Help:Bullet 4 link href')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  placeholder
                </a>
              ),
            ]}
          />
        </li>
        <li className={classes.listItem}>
          <Trans
            defaults="LicensesTable:Help:Bullet 5"
            values={{ link: t('LicensesTable:Help:Bullet 5 link') }}
            components={[
              (
                <a
                  className={classes.link}
                  href={t('LicensesTable:Help:Bullet 5 link href')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  placeholder
                </a>
              ),
            ]}
          />
        </li>
        <li className={classes.listItem}>
          <Trans
            defaults="LicensesTable:Help:Bullet 6"
            values={{ link: t('LicensesTable:Help:Bullet 6 link') }}
            components={[
              (
                <a
                  className={classes.link}
                  href={t('LicensesTable:Help:Bullet 6 link href')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  placeholder
                </a>
              ),
            ]}
          />
        </li>
        <li className={classes.listItem}>
          <Trans
            defaults="LicensesTable:Help:Bullet 7"
            values={{ link: t('LicensesTable:Help:Bullet 7 link') }}
            components={[
              (
                <a
                  className={classes.link}
                  href={t('LicensesTable:Help:Bullet 7 link href')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  placeholder
                </a>
              ),
            ]}
          />
        </li> 
        <li className={classes.listItem}>
          <Trans
            defaults="LicensesTable:Help:Bullet 8"
            values={{ link: t('LicensesTable:Help:Bullet 8 link') }}
            components={[
              (
                <a
                  className={classes.link}
                  href={t('LicensesTable:Help:Bullet 8 link href')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  placeholder
                </a>
              ),
            ]}
          />
        </li>
        <li className={classes.listItem}>
          <Trans
            defaults="LicensesTable:Help:Bullet 9"
          />
        </li>
        <li className={classes.listItem}>
          <Trans
            defaults="LicensesTable:Help:Bullet 10"
          />
        </li>
      </ol>

      <Title component="h3">{t('GuidePage:Helpful sources')}</Title>
      <p>
        <Trans
          defaults="GuidePage:helpfulSources:api text"
          values={{ placeholder: t('GuidePage:helpfulSources:api linkName') }}
          components={[
            (
              <a
                className={classes.link}
                href={t('GuidePage:helpfulSources:api link', { locale: getLocale() })}
                target="_blank"
                rel="noopener noreferrer"
              >
                placeholder
              </a>
            ),
          ]}
        />
      </p>
      <p>
        <Trans
          defaults="GuidePage:helpfulSources:help text"
          values={{ placeholder: t('GuidePage:helpfulSources:help linkName') }}
          components={[
            (
              <a
                className={classes.link}
                href={t('GuidePage:helpfulSources:help link', { locale: getLocale() })}
                target="_blank"
                rel="noopener noreferrer"
              >
                placeholder
              </a>
            ),
          ]}
        />
      </p>
      <p>
        <Trans
          defaults="GuidePage:helpfulSources:support text"
          values={{ placeholder: t('GuidePage:helpfulSources:support linkName') }}
          components={[
            (
              <a
                className={classes.link}
                href={t('GuidePage:helpfulSources:support link', { locale: getLocale() })}
                target="_blank"
                rel="noopener noreferrer"
              >
                placeholder
              </a>
            ),
          ]}
        />
      </p>
    </Grid>
  );
};
