import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(8),
  },
  activationHelp: {
    padding: theme.spacing(3, 6),
  },
  subtitle: {
    fontWeight: 900,
    margin: theme.spacing(3, 0),
  },
  docText: {
    fontWeight: 500,
  },
  docLink: {
    marginLeft: theme.spacing(0.5),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  link: {
    color: theme.palette.primary.main,
  },
  listItem: {
    margin: theme.spacing(1, 0),
  },
});
