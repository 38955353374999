export type LicenseItemsRequest = {}

export type LicenseItem = {
  licenseId: string;
  licenseType: number;
  activationDate: { seconds: string };
  expirationDate: { value: {seconds: string} };
  daysLeftToUse: { value: number };
  currentAssetsCount: string;
  assetsCount: string;
  activationBlobFileName: string;
  ksnConfigFileName: string;
  kataId: string;
  licenseBlocked: boolean;
  productEdition: number;
  region: string;
  company: string;
  isDeleted: boolean;
  isActive: boolean;
  name: string;
  activationLicenseId: string;
}

export type AssetsLimit = {
  limitAssets: number;
}

export type LicensesState = {
  isLoading: boolean;
  request: LicenseItemsRequest | undefined;
  error: undefined | unknown;
  licenses: LicenseItem[];
  columns: any;
  filters: any;
}

export const LICENSE_TYPES = [
  'Unknown',
  'Commercial',
  'Beta',
  'Trial',
  'Test',
  'OEM',
  'Subscription',
  'Subscription limit',
  'Free',
];

export const PRODUCT_EDITIONS = [
  'Unknown edition',
  'Optimum',
  'Expert',
];
