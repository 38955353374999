import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {},
  licenseId: {
    color: theme.palette.text.secondary,
  },
  button: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  activationHelp: {
    padding: theme.spacing(3, 6),
  },
  subtitle: {
    fontWeight: 900,
    margin: theme.spacing(3, 0),
  },
  docText: {
    fontWeight: 500,
  },
  docLink: {
    marginLeft: theme.spacing(0.5),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  expirationHighlighted: {
    color: theme.palette.error.dark,
    fontWeight: 'bold',
  },
  link: {
    color: theme.palette.primary.main,
  },
  listItem: {
    margin: theme.spacing(1, 0),
  },
  configLink: {
    fontWeight: 900,
    color: theme.palette.primary.main,
  },
});
