export type ActivationState = {
  validCode: boolean | null;
  regions: string[];
  code: null | string;
  isLoading: boolean;
  region: string;
  error: string | null;
  isLicenseExists: boolean;
  expectedOrigin?: string;
  assetsCount: number | null;
  assetsLimit: number | null;
  daysLeftToUse: number | null;
  clientIsActive: boolean | null;
  licenseAgreementStep: boolean;
  licenseSuccessStep: boolean;
  isSkippedUpdateNotice: boolean;
  solution: string;
  licenseText: string;
  activatedRegion: string;
  company: string;
  agreements: AgreementOffer[] | null;
  agreementsForActivation: { [key: string]: Agreement } | null;
  agreement: Agreement | null;
  showAgreement: boolean;
}

export interface RequestActivationAction {
  type: string;
  payload: string;
}

export interface LicenseInfo {
  licenseId: string | null;
  licenseType: string | null;
  activationDate: number | null;
  expirationDate: number | null;
  assetsCount: number | null;
  daysLeftToUse: number | null;
  activationBlobFileName: string | null;
  ksnConfigFileName: string | null;
  isLicenseBlocked: boolean;
  kataId?: string;
  solution: string;
  region: string;
  company: string;
}

export interface OrganizationInfo {
  company: string;
  daysLeftToUse: number | null;
  assetsCount: number;
  assetsLimit: number;
  region: string;
  solution: Solution;
  clientIsActive: boolean;
  readOnlyUi: {
    licenses: boolean;
    tenants: boolean;
  };
}

export type LicenseInfoSocketPayload = {
  license: LicenseInfo;
}

export interface LicenseText {
  licenseContent: string;
}

export type PostConfirmApiPayload = {
  activationCode: string;
  region: string;
  kata?: string;
  idToken: string;
}

export const EUROPE = 'europe';
export const RUSSIA = 'russia';
export const REST = 'rest';
export const C2P = 'c2p';
export const TNC = 'tnc';

export type LicenseDocType = 'c2p' | 'tnc';

export enum Solution {
  'Unknown edition' = 'Unknown edition',
  'Optimum' = 'Optimum',
  'Expert' = 'Expert',
  'Basic' = 'Basic',
  'Advanced' = 'Advanced',
  'Prime' = 'Prime',
}

export enum AgreementDecision {
  ACCEPTED,
  DECLINED,
  NOT_DECIDED
}

interface AgreementMeta {
  agreementId: number;
  title: string;
  version: number;
}

export enum AgreementMIME {
  PDF,
  HTML
}

export enum AgreementLanguage {
  ru,
  en
}

export enum AgreementType {
  MDR,
  KSC
}

export interface AgreementOffer {
  meta: AgreementMeta;
  clientId: string;
  decision: AgreementDecision;
}

export interface Agreement {
  meta: AgreementMeta;
  mime: AgreementMIME;
  language: AgreementLanguage;
  body: any;
  type: AgreementType;
}

export interface AgreementPayload {
  title: string;
  language: AgreementLanguage;
  mime: AgreementMIME;
  lastAcceptedBy?: string | null;
}

export enum AgreementTitles {
  Agreement = 'MDR Agreement',
  DPA = 'MDR DPA',
}

export interface AgreementForActivationPayload {
  titles: string[];
  language: AgreementLanguage;
  mime: AgreementMIME;
}

export interface AgreementDecidePayload {
  agreementId: number;
  accepted: boolean;
}
