import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ActivationState,
  Agreement,
  AgreementDecidePayload,
  AgreementDecision, AgreementForActivationPayload,
  AgreementOffer,
  AgreementPayload,
  AgreementType,
  LicenseDocType,
  OrganizationInfo,
} from './types';

const name = 'activation';

export const confirmRequest = createAction<number[] | undefined>(`${name}/confirmRequest`);
export const validateRequest = createAction<string>(`${name}/validateRequest`);
export const infoRequest = createAction(`${name}/infoRequest`);
export const getLicenseRequest = createAction(`${name}/getLicenseRequest`);
export const downloadMDRConfigRequest = createAction<number>(`${name}/downloadMDRConfigRequest`);
export const downloadLicenseDocRequest = createAction<LicenseDocType>(`${name}/downloadLicenseDoc`);
export const agreementListRequest = createAction<AgreementType>(`${name}/agreementListRequest`);
export const agreementListForActivationRequest = createAction<AgreementForActivationPayload>(
  `${name}/agreementListForActivationRequest`,
);
export const agreementRequest = createAction<AgreementPayload>(`${name}/agreementRequest`);
export const agreementDecideRequest = createAction<AgreementDecidePayload>(`${name}/agreementDecideRequest`);

const initialState: ActivationState = {
  validCode: null,
  regions: [],
  code: null,
  isLoading: false,
  isLicenseExists: false,
  region: '',
  isSkippedUpdateNotice: false,
  error: '',
  daysLeftToUse: null,
  assetsCount: null,
  assetsLimit: null,
  licenseAgreementStep: false,
  licenseSuccessStep: false,
  solution: '',
  licenseText: '',
  activatedRegion: '',
  company: '',
  agreements: null,
  agreementsForActivation: null,
  agreement: null,
  showAgreement: false,
  expectedOrigin: undefined,
  clientIsActive: null,
};

const activationSlice = createSlice({
  name,
  initialState,
  reducers: {
    start(state) {
      state.isLoading = true;
      state.error = null;
      state.expectedOrigin = undefined;
    },
    activationExist(state) {
      state.isLicenseExists = true;
    },
    originMismatch(state, { payload: expectedOrigin }: PayloadAction<string>) {
      state.validCode = true;
      state.assetsCount = 0;
      state.isLoading = false;
      state.expectedOrigin = expectedOrigin;
    },
    validateSuccess(state, action: PayloadAction<any>) {
      state.validCode = Boolean(action.payload.isValid);
      state.regions = action.payload.regions;
      if (action.payload.regions.length === 1) {
        [state.region] = action.payload.regions;
      }
      state.assetsCount = parseInt(action.payload.assetsCount, 10) || 0;
      state.isLoading = false;
      state.error = null;
    },
    confirmSuccess(state) {
      state.isLicenseExists = true;
      state.isLoading = false;
      state.error = null;
      state.validCode = null;
      state.code = null;
      state.licenseAgreementStep = false;
      state.licenseSuccessStep = true;
      state.licenseText = '';
    },
    infoSuccess(state, action: PayloadAction<OrganizationInfo>) {
      const {
        daysLeftToUse,
        assetsCount,
        assetsLimit,
        solution,
        region,
        company,
        clientIsActive,
      } = action.payload;
      state.clientIsActive = clientIsActive;
      state.assetsCount = Number(assetsCount);
      state.assetsLimit = Number(assetsLimit);
      state.daysLeftToUse = daysLeftToUse;
      state.solution = solution;
      state.isLoading = false;
      state.activatedRegion = region;
      state.company = company;
    },
    getLicenseSuccess(state, action: PayloadAction<string>) {
      state.licenseText = action.payload;
    },
    decline(state) {
      state.validCode = null;
      state.code = null;
      state.isLoading = false;
      state.error = null;
      state.licenseAgreementStep = false;
      state.licenseSuccessStep = false;
      state.licenseText = '';
    },
    setRegion(state, action: PayloadAction<string>) {
      state.region = action.payload;
    },
    error(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.licenseAgreementStep = false;
      state.licenseSuccessStep = false;
      state.licenseText = '';
    },
    clear(state) {
      state.error = null;
      state.isLoading = false;
      state.licenseAgreementStep = false;
      state.licenseSuccessStep = false;
      state.code = null;
      state.licenseText = '';
    },
    toNextStep(state, action: PayloadAction<string>) {
      state.licenseAgreementStep = true;
      state.code = action.payload;
    },
    setActivationCode(state, action: PayloadAction<string>) {
      state.code = action.payload;
    },
    skipUpdateNotice(state) {
      state.isSkippedUpdateNotice = true;
    },
    agreementListSuccess(state, action: PayloadAction<AgreementOffer[] | null>) {
      state.agreements = action.payload;
    },
    agreementListForActivationSuccess(state, action: PayloadAction<{ [key: string]: Agreement } | null>) {
      state.agreementsForActivation = action.payload;
    },
    agreementStart(state) {
      state.showAgreement = true;
    },
    agreementSuccess(state, action: PayloadAction<Agreement | null>) {
      state.agreement = action.payload;
    },
    agreementDecideStart(state) {
      state.isLoading = true;
    },
    agreementDecideSuccess(state, action: PayloadAction<AgreementOffer | null>) {
      const agreement = action.payload;
      if (state.agreements && agreement && agreement.decision === AgreementDecision.ACCEPTED) {
        state.agreements = state.agreements.filter(agr => agr.meta.agreementId !== agreement.meta.agreementId);
      }
      state.isLoading = false;
      state.showAgreement = false;
      state.agreement = null;
    },
  },
});

export const {
  error,
  clear,
  start,
  decline,
  validateSuccess,
  infoSuccess,
  confirmSuccess,
  activationExist,
  setRegion,
  setActivationCode,
  toNextStep,
  skipUpdateNotice,
  getLicenseSuccess,
  agreementListSuccess,
  agreementListForActivationSuccess,
  agreementStart,
  agreementSuccess,
  agreementDecideStart,
  agreementDecideSuccess,
  originMismatch,
} = activationSlice.actions;

export default activationSlice.reducer;
