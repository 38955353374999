import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'store/hook';
import { DateTime } from 'components/DateTime';
import { LinkButton } from 'components/LinkButton';
import { Loading } from 'components/Loading';
import { SuccessCircleIcon } from 'assets/icons/SuccessCircleIcon';
import { DataLine } from 'pages/LicenseStatePage/components/DataLine';
import { LICENSE_STATE_PAGE } from 'global/routes';
import { PRODUCT_EDITIONS, LicenseItem, LICENSE_TYPES } from 'services/licenses/types';
import styles from './styles';

const useStyles = makeStyles(styles);

export const ActivationSuccess = () => {
  const { t } = useTranslation('LicenseStatePage');
  const classes = useStyles();

  const lastActivatedLicense = useSelector(
    ({ licenses: { licenses } }) => {
      if (licenses.length === 0) {
        return null;
      }
      let last: LicenseItem = licenses[0];
      licenses.forEach(license => {
        if (parseInt(last.activationDate.seconds, 10) < parseInt(license.activationDate.seconds, 10)) {
          last = license;
        }
      });

      return last;
    },
  );
  
  if (lastActivatedLicense === null) {
    return <Loading />;
  }

  const {
    licenseType,
    activationDate,
    expirationDate,
    assetsCount,
    daysLeftToUse,
    productEdition,
  } = lastActivatedLicense;

  const type = LICENSE_TYPES[licenseType];
  const solution = PRODUCT_EDITIONS[productEdition];
  
  return (
    <Box className={classes.container}>
      <SuccessCircleIcon className={classes.icon} />

      <Typography className={classes.title} variant="h1" component="h1">
        {t('Application activated successfully')}
      </Typography>

      <Box className={classes.data}>
        {!licenseType ? (
          <Loading />
        ) : (
          <>
            <DataLine title={t('License type')} value={type ? t(`licenseTypes:${type}`) : null} />
            <DataLine title={t('Solution')} value={solution ? t(solution) : null} />
            <DataLine
              title={t('Activation date')}
              value={activationDate ? <DateTime timestamp={parseInt(activationDate.seconds, 10)} /> : null}
            />
            {
              typeof expirationDate?.value?.seconds === 'string' 
                ? (
                  <DataLine
                    title={t('Expiration date')}
                    value={<DateTime timestamp={parseInt(expirationDate.value.seconds, 10)} />}
                  />
                )
                : null
            }
            {
              typeof daysLeftToUse?.value === 'number'
                ? (<DataLine title={t('Days left to use')} value={daysLeftToUse.value} />)
                : null
            }
            <DataLine title={t('Number of assets')} value={assetsCount} />
          </>
        )}
      </Box>

      <LinkButton
        className={classes.btn}
        size="large"
        variant="outlined"
        color="primary"
        to={LICENSE_STATE_PAGE}
      >
        {t('Ok')}
      </LinkButton>
    </Box>
  );
};
